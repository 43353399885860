<template>
    
    <h3>
        Demo for online simulator
    </h3>
    <p>
       You should subscribe for online simulator to access full version
    </p>
  <div class="iframe-container">
    <iframe :src="iframeSrc" frameborder="0"></iframe>
  </div>
  </template>
  
  <script>
  export default {
    name: 'FullWidthIframe',
    mounted() {
    const metaViewport = document.createElement('meta');
    metaViewport.name = 'viewport';
    metaViewport.content = 'width=device-width, initial-scale=1.0';
    document.head.appendChild(metaViewport);
  },
    data() {
      return {
        iframeSrc: 'https://wokwi.com/projects/291958456169005577',
      };
    },
  }
  </script>
  
  <style scoped>
  body, html {
    margin: 0;
    padding: 0;
    height: 100%;
    overflow: hidden; /* To prevent scrollbars caused by negative margin */
  }.iframe-container {
  overflow: hidden; /* Hide the parts of the iframe that are outside the container */
  position: relative; /* Establish a positioning context */
  padding-top: 30px; /* Push content down */
  height: calc(100vh - 30px); /* Full viewport height minus the clipped top part */
}

iframe {
  position: absolute; /* Position absolutely within the container */
  top: -38px; /* Move the iframe content up */
  left: 0;
  width: 100%;
  height: 100vh; /* Set iframe height to be the full viewport height */
  border: none; /* Remove border */
}
  </style>
  